import {Controller} from '@hotwired/stimulus';
import {useResize, useClickOutside, useTransition} from 'stimulus-use';

import {isMatching} from '../../js/utils/bph';

export default class extends Controller {
    static targets = ['hamburger', 'menu'];

    connect() {
        if (ResizeObserver) {
            useResize(this);
        }
        useClickOutside(this);
        useTransition(this, {
            element: this.menuTarget,
            // enterActive: 'transition ease-out duration-150',
            // enterFrom: 'transform -translate-x-1/2',
            // enterTo: 'transform translate-x-0',
            leaveActive: 'transition ease-in duration-150',
            leaveFrom: 'transform translate-x-0',
            leaveTo: 'transform -translate-x-1/2',
        });

        this.activeSubmenu = null;

        if (!isMatching('dt')) {
            this.scrollHeight = window.scrollY;
            window.addEventListener('scroll', this.scroll, {passive: true});
        }
    }

    disconnect() {
        this.close();
        this.hideDropdown();
        if (!isMatching('dt')) {
            window.removeEventListener('scroll', this.scroll);
        }
    }

    scroll() {
        let current = window.scrollY,
            header = document.querySelector('header'),
            sortBy = document.querySelector('.scroll-by');

        if (!header) {
            return;
        }

        let expandedDropdowns = header.querySelectorAll("button[aria-expanded='true']");
        if (expandedDropdowns.length === 0) {
            if (this.scrollHeight > current) {
                header.style.top = '0px';
                if (sortBy) {
                    sortBy.style.top = '56px';
                }
            } else if (current > 110) {
                header.style.top = '-56px';
                if (sortBy) {
                    sortBy.style.top = '0px';
                }
            }
        }

        this.scrollHeight = current;
    }

    resize({width}) {
        // close main menu when over "dt" breakpoint
        if (width >= 1035 && this.transitioned) {
            this.close();
        }
    }

    clickOutside() {
        this.hide();
    }

    open() {
        this.element.classList.add('overflow-auto', 'h-full');
        this.element.classList.remove('h-14');
        this.hamburgerTarget.classList.add('active');
        document.querySelector('body').classList.add('overflow-hidden');

        if (typeof this.enter === 'function') {
            this.enter();
        } else if (this.hasMenuTarget) {
            this.menuTarget.classList.remove('hidden');
        }
    }

    close() {
        if (typeof this.leave === 'function') {
            this.leave();
        } else if (this.hasMenuTarget) {
            this.menuTarget.classList.add('hidden');
        }

        this.element.classList.remove('overflow-auto', 'h-full');
        this.element.classList.add('h-14');
        this.hamburgerTarget.classList.remove('active');
        document.querySelector('body').classList.remove('overflow-hidden');
    }

    toggle() {
        if (this.transitioned) {
            this.close();
        } else {
            this.open();
        }
    }

    hide() {
        if (this.activeSubmenu) {
            this.hideDropdown(this.activeSubmenu);
            this.activeSubmenu = null;
        }
        this.close();
    }

    toggleDropdown(e) {
        e.preventDefault();
        let menuItem = e.currentTarget.parentElement;
        if (menuItem) {
            if (this.activeSubmenu) {
                this.hideDropdown(this.activeSubmenu);
            }
            if (this.activeSubmenu !== menuItem) {
                this.showDropdown(menuItem);
                this.activeSubmenu = menuItem;
                let images = menuItem.querySelectorAll('img');
                images.forEach((el) => {
                    el.removeAttribute('loading');
                });
            } else {
                this.activeSubmenu = null;
            }
        }
    }

    showDropdown(element) {
        let submenu = this.menuDropdown(element);
        let link = this.menuDropdownMore(element);
        element.classList.add('open');
        submenu.classList.remove('hidden');
        if (link) {
            link.classList.add('active');
        }
        this.dispatch('mainMenuOpen');
    }

    hideDropdown(element = null) {
        if (!element && this.activeSubmenu) {
            element = this.activeSubmenu;
        }

        if (element) {
            let submenu = this.menuDropdown(element);
            let link = this.menuDropdownMore(element);
            submenu.classList.add('hidden');
            element.classList.remove('open');
            if (link) {
                link.classList.remove('active');
            }
        }
    }

    menuDropdown(element) {
        return element.querySelector('.dropdown-menu');
    }

    menuDropdownMore(element) {
        return element.querySelector('.more');
    }

    pickLang(e) {
        e.preventDefault();
        e.stopPropagation();
        if (typeof e.params.lang !== 'undefined') {
            window.changeLanguage(e.params.lang);
        }
    }
}
